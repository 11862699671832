
<div class="carousel">
    <button class="left" on:click={left}>
        <slot name="left-control"></slot>
    </button>
    <div class="slides" bind:this={siema}>
        <slot></slot>
    </div>
    <ul bind:this={pipContainer}>
        {#each pips as pip, i}
            <li on:click={() => go(i)}></li>
        {/each}
    </ul>
    <button class="right" on:click={right}>
        <slot name="right-control"></slot>
    </button>
    <div bind:this={labelContainer}>
        {#each labels as label}
            {#if label}
                <p class="c-carousel__label">
                    {@html label}
                </p>
            {:else}
                <p></p>
            {/if}
        {/each}
    </div>
</div>

<script>
    import Siema from 'siema'
    import { onMount } from 'svelte'
    
    export let perPage = 3
    export let loop = true
    export let autoplay = 0
    export let labels

    let siema
    let controller
    let timer
    let labelContainer
    let labelElement
    let pipContainer

    $: pips = controller ? controller.innerElements : []
    
    onMount(() => {
        controller = new Siema({
            selector: siema,
            perPage,
            loop
        })

        update();
        
        autoplay && setInterval(right, autoplay)

        return () => {
            autoplay && clearTimeout(timer)
            controller.destroy()
        }
    })

    function update () {
        for (let i = 0; i < labelContainer.children.length; i++) {
            if (i === controller.currentSlide) {
                labelContainer.children[i].classList.remove("hidden")
            } else {
                labelContainer.children[i].classList.add("hidden")
            }
        }
        for (let i = 0; i < pipContainer.children.length; i++) {
            if (i === controller.currentSlide) {
                pipContainer.children[i].classList.add("active")
            } else {
                pipContainer.children[i].classList.remove("active")
            }
        }
    }
    
    function left () {
        controller.prev()
        update();
    }
    
    function right () {
        controller.next()
        update();
    }

    function go (index) {
        controller.goTo(index)
        update();
    }
</script>
