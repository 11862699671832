<script>
    export let before = {};
    export let after = {};

    import { onMount } from 'svelte';
    import md5 from 'md5';

    let active = false;
    let beforeElement, beforeBar, scrollElement, sliderElement;

    function slide(x) {
        let transform = Math.max(
            0,
            Math.min(x, sliderElement.offsetWidth)
        );
        beforeElement.style.width = transform + "px";
        beforeBar.style.width = transform + "px";
        scrollElement.style.left = (transform - 25) + "px";
    }

    function handleMouseDown() {
        active = true;
    }

    function handleMouseUp() {
        active = false;
    }

    function handleMouseLeave() {
        if (active) {
            active = false;
            let box = sliderElement.getBoundingClientRect();
            if (event.pageX < box.left) {
                slide(0);
            } else if (event.pageX > box.right) {
                slide(box.right);
            }
        }
    }

    function handleMouseMove() {
        if (active) {
            let mousePosX = event.pageX;
            let x = mousePosX - sliderElement.getBoundingClientRect().left;
            slide(x);
        }
    }

    onMount(() => {
        setTimeout(function() {
            slide(Math.floor(sliderElement.offsetWidth * 0.7));
        }, 500)
    });

</script>

<div class="c-image-slider">
    <div class="c-image-slider__wrapper">
        <div class="icon good right"></div>
        <div class="icon bad left"></div>
        <div class="c-image-slider__slider-el" bind:this={sliderElement} on:mousemove={handleMouseMove} on:mouseup={handleMouseUp} on:mouseleave={handleMouseLeave}>
            <img src={after.image} alt={`After: ${after.text}`} draggable="false" class="sizer" />
            <div class="after">
                <img src={after.image} alt={`After: ${after.text}`} draggable="false"  />
            </div>
            <div class="before" bind:this={beforeElement}>
                <img src={before.image} alt={`Before: ${before.text}`} draggable="false" />
            </div>
            <div class="c-image-slider__scroller" bind:this={scrollElement} on:mousedown={handleMouseDown}>
                <svg xmlns="http://www.w3.org/2000/svg" 
                    width="100" height="100" viewBox="0 0 100 100"><polygon points="0 50 37 68 37 32 0 50" style="fill:#fff"/><polygon 
                    points="100 50 64 32 64 68 100 50" style="fill:#fff"/>
                </svg>
            </div>
        </div>
        <div class="c-image-slider__bars">
            <div class="c-image-slider__after-bar"></div>
            <div bind:this={beforeBar} class="c-image-slider__before-bar"></div>
        </div>
    </div>
    <div class="c-image-slider__flex-row">
        <div class="c-image-slider__flex-half">
            {#if before.heading}
                <h4 class="c-image-slider__caption-heading">
                    {@html before.heading}
                </h4>
            {/if}
            {#if before.text}
                <p class="c-image-slider__caption-text">
                    {@html before.text}
                </p>
            {/if}
        </div>
        <div class="c-image-slider__flex-half">
            {#if after.heading}
                <h4 class="c-image-slider__caption-heading">
                    {@html after.heading}
                </h4>
            {/if}
            {#if after.text}
                <p class="c-image-slider__caption-text">
                    {@html after.text}
                </p>
            {/if}
        </div>
    </div>
</div>


