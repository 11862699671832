<script>
    export let src = "";
    export let alt;
    export let image_style;
    export let expandable = true;
    export let rounded = false;
    export let shadow = false;

    let containerElement, toggleButton, imageElement;
    let expanded = false;

    let className = 'c-image';
    if (!expanded) {
        if (image_style) className += ` ${image_style}`; 
        if (rounded == true) className += " c-image--rounded"; 
        if (shadow == true) className += " c-image--shadow";
    } 
    
    if (expandable) {
        className += " c-image--expandable"; 
    }

    function toggleExpand() {
        if (expanded) {
            containerElement.classList.remove("c-image--expanded");
            toggleButton.classList.remove("c-image__expand-button--expanded");
            imageElement.classList.remove("c-image__img--expanded");
        } else {
            containerElement.classList.add("c-image--expanded");
            toggleButton.classList.add("c-image__expand-button--expanded");
            imageElement.classList.add("c-image__img--expanded");
        }
        expanded = !expanded;
    }

</script>

<div bind:this={containerElement} class={className}>
    <button bind:this={toggleButton} class="c-image__expand-button" on:click={toggleExpand}></button>
    <img bind:this={imageElement} class="c-image__img" src={src} alt={alt}/>
</div>


