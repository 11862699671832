<script>
    import Carousel from 'Components/carousel.svelte';
    import Image from 'Components/image-subcomponent.svelte';

    export let slides = [];
    let labels = slides.map(slide => slide.alt_text);
</script>

<div class="c-carousel">
    <Carousel perPage={1} labels={labels}>
        <span class="control left" slot="left-control">
            
        </span>
        {#each slides as slide}
            <Image 
                src={slide.image}
                expandable={false} />
        {/each}
        <span class="control right" slot="right-control">
            
        </span>
    </Carousel>
</div>