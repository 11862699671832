<script>
    export let annotations = [];
    export let image = {};
    export let offset = 0;

    let focused = null;
    let startAtOneOffset = parseInt(offset) + 1;

    let setFocused = (n) => {
        focused = n;
    }
</script>

<div class="c-annotated-image">
    <div class="c-annotated-image__image">
        <div class="c-annotated-image__dots">
            {#each annotations as annotation, i}

            <button 
                class="c-annotated-image__dot" 
                class:is-focused={i+startAtOneOffset === focused}
                style="top: {annotation.y}%; left: {annotation.x}%;"
                on:focus={() => setFocused(i+startAtOneOffset)} 
                on:blur={() => setFocused(null)}>
                { i+startAtOneOffset } 
            </button>
            
            {/each}
        </div>
        <div class="c-image"
            class:c-image--rounded={image.rounded}
            class:c-image--shadow={image.shadow}>
            <img class="c-image__img" src={image.image_path} alt={image.alt_text}/>
        </div>
    </div>
    <div class="c-annotated-image__annotations">
        {#each annotations as annotation, i}

        <button 
            class="c-annotated-image__annotation"
            class:is-focused={i+startAtOneOffset === focused}
            on:focus={() => setFocused(i+startAtOneOffset)} 
            on:blur={() => setFocused(null)} >
            <span class="c-annotated-image__number">{ i+startAtOneOffset }</span>
            <span class="c-annotated-image__text">{ annotation.text }</span>
        </button>

        {/each}
    </div>
</div>