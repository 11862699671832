<script>
    import ImageCarousel from 'Components/image-carousel.svelte';

    export let name;
    export let displayType;
    export let slides = [];
    
    let showInterface = false;
    let showGuides = false;

    let interfaceButtonId = `${name}-interface`;
    let interfaceButtonClass = `c-toggle ${showInterface ? "c-toggle--checked" : ""}`;

    let guideButtonId = `${name}-interface`;
    let guideButtonClass = `c-toggle ${showGuides ? "c-toggle--checked" : ""}`;

    let imageClass = `c-asset-reference__image ${displayType ? `c-asset-reference__image--${displayType}` : ""}`;

    function toggleInterface() {
        showInterface = !showInterface;
        console.log(`Show interface ${showInterface}`)
        interfaceButtonClass = `c-toggle ${showInterface ? "c-toggle--checked" : ""}`;
    }
    function toggleGuides() {
        showGuides = !showGuides;
        console.log(`Show guides ${showGuides}`)
        guideButtonClass = `c-toggle ${showGuides ? "c-toggle--checked" : ""}`;
    }
</script>

<div class="c-asset-reference__controls">
    <div class="c-asset-reference__flex c-asset-reference__flex--space-between">
        <div class="c-asset-reference__flex">
            <div class="c-asset-reference__toggle"> 
                <label class="c-asset-reference__control-label">Interface</label>

                <button id={interfaceButtonId} class={interfaceButtonClass} on:click={toggleInterface}>
                    <div class="c-toggle__knob"></div>
                </button>

                <label class="c-asset-reference__control-state-label">Off</label>
            </div>

            <div class="c-asset-reference__toggle"> 
                <label class="c-asset-reference__control-label">Guides</label>
                
                <button id={guideButtonId} class={guideButtonClass} on:click={toggleGuides}>
                    <div class="c-toggle__knob"></div>
                </button>

                <label class="c-asset-reference__control-state-label">Off</label>
            </div>
        </div>

        <div>
            <label class="c-asset-reference__control-label">Text Direction</label>
            <select disabled class="c-asset-reference__direction-selection">
                <option value="ltr">Left to Right</option>
                <option value="rtl">Right to Left</option>
            </select>
        </div>
    </div>
</div>

<div class={imageClass}>
    <ImageCarousel slides={slides} />
</div>
    
  
