import Hello from './components/Hello.svelte';
import Slider from './components/image-slider.svelte';
import Annotations from './components/annotated-image.svelte';
import Carousel from './components/image-carousel.svelte';
import Image from './components/image-subcomponent.svelte';
import AssetReference from './components/asset-reference.svelte';

// inserts value into json, at position determined by lookups
// lookups should be something like '[myObj,myArray,1,name]'
function insertIntoNestedJSON(json, lookups, value) {
    if (lookups.length === 1) {
        json[lookups[0]] = decodeURIComponent(value);
        console.log("insert", json, lookups[0], value)
        return json;
    }
    if (lookups.length > 1) {
        let lookup = lookups.shift();
        if (!json[lookup]) json[lookup] = {};
        return insertIntoNestedJSON(json[lookup], lookups, value)
    }
    console.warn("Something went wrong inserting JSON", json, lookups)
    return {};
}

function getDataProps(element, data) {
    let dataPropElements = element.querySelectorAll('[data-prop]');
    dataPropElements.forEach(child => {
        let dataProp = child.getAttribute('data-prop');
        let propContent = child.innerHTML;
        insertIntoNestedJSON(data, dataProp.split('.'), propContent)
    });
    console.log(data);
    return data;
}

let svelteItems = [
    {
        component: Hello,
        selector: ".js-hello-area",
        getProps: (el) => ({
            target: el.dataset.name
        })
    },
    {
        component: Annotations,
        selector: ".js-annotation-area",
        getProps: (el) => {
            let data = {
                annotations: JSON.parse(el.dataset.annotations.replace(/&apos/g, `'`).replace(/&quot/g, `"`)),
                image: JSON.parse(el.dataset.image.replace(/&apos/g, `'`).replace(/&quot/g, `"`)),
                offset_number: parseInt(el.dataset.offset)
            }
            console.log(data)
            return getDataProps(el, data);
        }
    },
    {
        component: Carousel,
        selector: ".js-carousel-area",
        getProps: (el) => {
            let slides = el.dataset.slides.replace(/&apos/g, `'`).replace(/&quot/g, `"`);
            slides = JSON.parse(slides);
            for (let i = 0; i < slides.length; i++) {
                if (!slides[i].image) slides[i].image = slides[i].image_path
            }
            console.log(slides)
            let data = {
                slides: slides,
            }
            return getDataProps(el, data);
        }
    },
    {
        component: Image,
        selector: ".js-image-area",
        getProps: (el) => ({
            src: el.dataset.src,
            alt: el.dataset.alt,
            image_style: el.dataset.image_style,
            rounded: el.dataset.rounded,
            shadow: el.dataset.shadow,
        })
    },
    {
        component: Slider,
        selector: ".js-image-slider-area",
        getProps: (el) => {
            let data = {
                before: {
                    image: el.dataset.beforeImage
                },
                after: {
                    image: el.dataset.afterImage
                },
            };
            return getDataProps(el, data);
        }
    },
    {
        component: AssetReference,
        selector: ".js-asset-template-area",
        getProps: (el) => ({
            name: el.dataset.assetName,
            displayType: el.dataset.assetDisplayType,
            slides: JSON.parse(el.dataset.assetSlides),
        })
    },
    
]
window.svelteItems = svelteItems;

setTimeout(function() {
    for (let item of svelteItems) {
        item.instances = [];

        let elements = document.querySelectorAll(item.selector);
        for (let element of elements) {
            item.instances.push(new item.component({
                target: element,
                hydrate: true,
                props: item.getProps(element)
            }))
        }
    }
}, 2000)

let preSvelte = document.querySelectorAll(".pre-svelte");

for (let pre of preSvelte) {
    pre.addEventListener('mouseup', function() {
        alert("pre-svelte");
    })
}